// @flow
import * as React from 'react'
import {Button} from '../../../ui-components'
import UserStoryCard from './UserStoryCard'

import * as styles from './Community.module.css'

const USER_STORIES = [
  {
    color: 'blue',
    title: 'science on a budget',
    userQuote:
      '“I’ve done I don’t know how many hundreds of minipreps in my life. I don’t want to do another 3,000.”',
    userName: 'Nick Emery',
    userLab: 'DAMP Labs of Boston University',
    userVideo: 'https://player.vimeo.com/video/303374891',
    gtm: {action: 'click', category: 'b-hp', label: 'community-video1'},
  },
  {
    color: 'orange',
    title: 'flexibility in hacking',
    userQuote:
      '“There’s two reasons we chose Opentrons: it’s affordable and we can develop open-source software for it.”',
    userName: 'Keoni Gandall',
    userLab: 'Free Genes Project',
    userVideo: 'https://player.vimeo.com/video/290614758',
    gtm: {action: 'click', category: 'b-hp', label: 'community-video2'},
  },
  {
    color: 'green',
    title: 'sample management',
    userQuote:
      '"There’s days we have Opentrons transfer 800 samples. I would never be able to do as many samples by hand."',
    userName: 'Kaja Wasik',
    userLab: 'Gencove',
    userVideo: 'https://player.vimeo.com/video/290691212',
    gtm: {action: 'click', category: 'b-hp', label: 'community-video3'},
  },
]

export default function Community() {
  const publicationProps = {
    url: 'https://blog.opentrons.com/tag/community/',
    gtm: {
      action: 'click',
      category: 'b-hp',
      label: 'community-all',
    },
  }
  return (
    <>
      <div className={styles.community_container}>
        <h3 className={styles.h3}>Our Community</h3>
        <h5 className={styles.h5}>
          Explore how other labs automate with the OT-2
        </h5>
        <section className={styles.community_section}>
          {USER_STORIES.map((user, i) => (
            <UserStoryCard key={i} {...user} />
          ))}
        </section>
        <div className={styles.button_wrapper}>
          <Button
            Component="a"
            linkOutProps={publicationProps}
            className={styles.pub_button}
          >
            See what our customers are doing
          </Button>
        </div>
      </div>
    </>
  )
}
