// @flow

import * as React from 'react'

import * as styles from './NAC.module.css'

import {BulletPoint} from '../../ui-components'

import {WORKFLOW_BULLET_POINT_DATA} from './nucleic-acid-cleanup-data'

export default function Overview() {
  return (
    <div className={styles.overview_container}>
      <section>
        <h4 className={styles.h4}>Overview</h4>
        <p className={styles.p}>
          Nucleic acid cleanup is the process of purifying specific nucleic
          acids from an enzymatic reaction, or for creating a resuspension – a
          mixture suspending fine particles in fluid. Nucleic acid cleanup is a
          key step in many molecular biology workflows, including NGS library
          preparation. Using the workflow outlined here, scientists can increase
          their throughput, reduce errors, and save hours of repetitive work
          every week by automating their cleanups.
        </p>
        <br />
        <p className={styles.p}>
          At Opentrons, we recommend using{' '}
          <a
            className={styles.links}
            href="https://shop.opentrons.com/omega-biotek-mag-bind-totalpure-ngs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Omega Bio-tek Total-Pure NGS magnetic beads
          </a>{' '}
          for these protocols. These beads can purify and select particular size
          fragments for subsequent workflow steps. More importantly, we have
          shown in our lab that our liquid handling robot (the OT-2){' '}
          <a
            className={styles.links}
            href="https://s3.amazonaws.com/opentrons-protocol-library-website/Technical+Notes/Omega_Application_Note.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            produces high yield and high quality products when running cleanups
            with the Omega Bio-Tek Total-Pure NGS beads
          </a>{' '}
          (click the link to read our application note). By using these beads
          and activating the OT-2’s{' '}
          <a
            className={styles.links}
            href="https://shop.opentrons.com/magnetic-module-gen2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Magnetic Module
          </a>
          , the selected nucleic acids attach to the Magnetic bead and are
          pelleted on the side of the well. After resuspension and separation,
          the supernatant containing the purified product can be collected from{' '}
          <a
            className={styles.links}
            href="https://shop.opentrons.com/ot-2-robot/"
            target="_blank"
            rel="noopener noreferrer"
          >
            the OT-2
          </a>{' '}
          or used for your next experiment.
        </p>
        <br />
        <p className={styles.p}>
          In addition, we have a protocol that anyone can customize, download,
          and run on their own OT-2. We also have a tool to build a protocol
          that will work for any brand of magnetic beads, giving scientists the
          ability to vary all key parameters in their experiment and optimize
          purification using our Magnetic Module as a starting point.
        </p>
        <div className={styles.workflow}>
          <p className={styles.p}>
            This workflow can be used for the following applications:
          </p>
          <div>
            <BulletPoint names={WORKFLOW_BULLET_POINT_DATA} />
          </div>
        </div>
      </section>
    </div>
  )
}
