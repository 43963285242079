// @flow

import * as React from 'react'

import * as styles from './NAC.module.css'

import {ContentHalf, ResourceNote, LightBox} from '../../ui-components'

import VIDEO_STILL from './assets/video-still_magnetic-module.png'
import VIDEO_ICON from './assets/video-icon.svg'

import {RESOURCE_NOTE_DATA, RESOURCE_VIDEO} from './nucleic-acid-cleanup-data'

export default function Resources() {
  const [videoOpen, setVideoOpen] = React.useState(false)
  return (
    <div>
      <section>
        <h4 className={styles.h4}>Resources</h4>
        <div className={styles.resource_pdf}>
          <ContentHalf>
            <div>
              <article>
                <figure>
                  <div className={styles.resource_video}>
                    <img
                      className={styles.play_icon}
                      src={VIDEO_ICON}
                      alt=""
                      onClick={() => setVideoOpen(true)}
                    />
                    <img src={VIDEO_STILL} alt="" />
                  </div>
                </figure>
                <div className={styles.resource_caption}>
                  <span>VIDEO</span>
                  <p className={styles.p}>Magnetic Module</p>
                </div>
              </article>
            </div>
          </ContentHalf>
          <ContentHalf>
            <div className={styles.resources_content}>
              {RESOURCE_NOTE_DATA.map((resource, i) => {
                return (
                  <ContentHalf key={i} className={styles.resource_data_notes}>
                    <ResourceNote {...resource} />
                  </ContentHalf>
                )
              })}
            </div>
          </ContentHalf>
        </div>
      </section>
      {videoOpen && (
        <LightBox
          videoSrc={RESOURCE_VIDEO}
          onCloseClick={() => setVideoOpen(false)}
        />
      )}
    </div>
  )
}
