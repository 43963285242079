import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import NAC from '../components/NAC'

const NACPage = () => (
  <Page>
    <SEO
      title="Automated DNA Clean Up | Lab Automation from $5,000"
      description="Automate nucleic acid cleanup offers many benefits to traditional manual protocols, such as you don't have to do them manually, error reduction, cost savings, and more."
    />
    <NAC />
  </Page>
)

export default NACPage
