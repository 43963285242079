import {PRICES} from '../../prices'

export const WORKFLOW_DATA = [
  {
    imgSrc: require('./assets/step-1_200x150.png').default,
    stepNumber: '1',
    name: 'Select your protocol',
    content:
      'Start from scratch with the Protocol Designer, or use a Protocol Library template',
  },
  {
    imgSrc: require('./assets/step-2_200x150.png').default,
    stepNumber: '2',
    name: 'Place reagents onto robot',
    content:
      'Put premade PCR master mix on robot - or have the OT-2 make it for you. Combine master mix with forward and reverse primers.',
  },
  {
    imgSrc: require('./assets/step-3_200x150.png').default,
    stepNumber: '3',
    name: 'Calibrate & activate protocol',
    content: 'Confirm pipetting locations & hit "run" (takes < 5 minutes)',
  },
  {
    imgSrc: require('./assets/step-4_200x150.png').default,
    stepNumber: '4',
    name: 'Load robot & run',
    content:
      'After the OT-2 has prepped all your reactions, just run your PCR machine',
  },
  {
    imgSrc: require('./assets/step-5_200x150.png').default,
    stepNumber: '5',
    name: 'Collect purified sample',
    content: 'Collect your clean nucleic acids and continue your experiment',
  },
]

export const MATERIAL_DATA = [
  {
    url: 'https://shop.opentrons.com/ot-2-robot/',
    imgSrc: require('../../images/application/materials/opentrons_ot2.jpg')
      .default,
    name: 'OT-2 Liquid Handling Robot',
    price: 'Starting at under $10,000.00',
  },
  {
    url: 'https://shop.opentrons.com/magnetic-module-gen2/',
    imgSrc: require('../../images/application/materials/magnetic_module.jpg')
      .default,
    name: 'Magnetic Module',
    price: PRICES.MagneticModule,
  },
  {
    url: 'https://shop.opentrons.com/omega-biotek-mag-bind-totalpure-ngs/',
    imgSrc: require('../../images/application/materials/omega_mag_bind_ngs.jpg')
      .default,
    name: 'Omega Mag-Bind TotalPure NGS Kit',
    price: '$103.00',
  },
  {
    url: 'https://shop.opentrons.com/opentrons-300ul-tips-1000-refills/',
    imgSrc: require('../../images/application/materials/filter_tips.jpg')
      .default,
    name: '300 µL Tip Rack (incl. 9,600 tips)',
    price: '$500.00',
  },
  {
    url: 'https://shop.opentrons.com/8-channel-electronic-pipette/',
    imgSrc:
      require('../../images/application/materials/multi_channel_pipette.jpg')
        .default,
    name: '8-Channel Pipette',
    price: PRICES.MultiChannelPipette,
  },
]

export const NOTE_DATA = [
  {
    name: 'Reagents',
    note: 'This application has been fully tested and scientifically verified in our lab with reagent partner Omega Bio-Tek. We recommend you use Omega Bio-Tek Total-Pure NGS for this application. If you would like to use another kit, the OT-2 supports various kits for nucleic acid extraction. Please confirm you have all required reagents required for your particular kit when you receive it.',
  },
  {
    name: 'Labware',
    note: 'The OT-2 supports various labware for nucleic acid extraction. We support fully skirted .02 ml PCR plates, which are the best fit for the module itself. We can also support up to 2 ml deep well plates, which might be helpful for larger volume kits. We suggest that you find a labware definition in our software that closely matches the labware in your lab. Alternatively, you can use the ‘labware.create’ command to input the exact dimensions of your PCR plate. You can find more details about supported and custom labware on the OT-2 in our Labware Library.',
  },
  {
    name: 'Pipettes',
    note: 'Ensure that your OT-2 is fitted with suitable pipettes for the volumes you will need to work with. All of our pipettes can support these protocols, but pipette size can vary based on the size of the wash and the protocol you are using. For example, if you’re doing a 600 ml wash, you may need a p1000 pipette depending on your protocol. Please review our OT-2 Pipette White Paper for more information on our pipettes and pipetting volumes as low as 1 µL (1).',
  },
]

export const RESOURCE_NOTE_DATA = [
  {
    url: 'https://insights.opentrons.com/hubfs/Products/Modules/Magnetic%20Module%20White%20Paper.pdf',
    noteType: 'white paper',
    name: 'Magnetic Module',
    iconName: 'pdf-download',
  },
  {
    url: 'https://protocols.opentrons.com/protocol/omega_biotek_magbind_totalpure_NGS/',
    noteType: 'Technical bulletin',
    name: 'Omega TotalPure NGS',
    iconName: 'pdf-download',
  },
  {
    url: 'https://protocols.opentrons.com/protocol/nucleic_acid_purification_with_magnetic_beads/',
    noteType: 'Technical bulletin',
    name: 'General Magnetic bead NA Purification',
    iconName: 'pdf-download',
  },
  {
    url: 'https://s3.amazonaws.com/opentrons-protocol-library-website/Technical+Notes/Nucleic+Acid+Purification+with+Magnetic+Module+OT2+Technical+Note.pdf',
    noteType: 'application note',
    name: 'Nucleic Acid Purification',
    iconName: 'pdf-download',
  },
]

export const RELATED_CONTENT_DATA = [
  {
    url: 'https://blog.opentrons.com/the-power-of-open-source-in-biology-damp-labs-10k-biofoundry/',
    readInfo: 'BLOG - 6 MIN READ',
    title: `The Power of Open Source in Biology—DAMP Lab’s $10k Biofoundry`,
    content:
      'The DAMP Lab is a fledgling biofoundry within the new Biological Design Center at Boston University.',
  },
  {
    url: 'https://blog.opentrons.com/ngs-automation/',
    readInfo: 'BLOG - 4 MIN READ',
    title: `5 Reasons to Automate your NGS Workflow`,
    content:
      'With greater speed, lower costs, and higher throughput than first generation methods, next generation sequencing (NGS) has catapulted biological research into a new era.',
  },
]

export const MENU_DATA = {
  links: [
    'overview',
    'benefits',
    'workflow',
    'opentrons materials',
    'notes',
    'resources',
  ],
  url: '/nucleic-acid-cleanup',
  workflow: 'Interested in automating your PCR workflow?',
}

export const WORKFLOW_BULLET_POINT_DATA = [
  'PCR Product Purification',
  'NGS Library Clean-up',
  'Any other nucleic acid cleanup',
]

export const MATERIAL_BULLET_POINT_DATA = [
  'Omega Protocol',
  'Illumina TX Library Prep Post Amplification Clean-up',
  'Generic Purification Protocol',
]

export const BENEFIT_LIST_DATA = [
  {
    boldText: 'Automating monotonous, time-consuming cleanups',
    text: 'so you don’t have to do them',
  },
  {
    boldText: 'Reducing error and contamination',
    text: 'by minimizing human intervention',
  },
  {
    boldText: 'Stringing together complex workflows',
    text: 'requiring cleanup, like NGS Library Prep',
  },
  {
    boldText: 'Minimizing setup time',
    text: 'by utilizing pre-optimized and verified protocol + reagent bundle',
  },
]

export const RESOURCE_VIDEO = 'https://player.vimeo.com/video/299671341'
