// @flow

import * as React from 'react'

import Hero from './Hero'
import StickyMenu from './StickyMenu'
import Overview from './Overview'
import Benefits from './Benefits'
import Workflow from './Workflow'
import OpentronsMaterials from './OpentronsMaterials'
import Notes from './Notes'
import Resources from './Resources'
import Related from './Related'
import Community from '../homepage/Community'

import {LightBox} from '../../ui-components'

import * as styles from './NAC.module.css'

const userVideo = `https://player.vimeo.com/video/321420718`

export default function PCRSamplePrep() {
  const [videoOpen, setVideoOpen] = React.useState<boolean>(false)
  return (
    <>
      <Hero />
      <div className={styles.container}>
        <StickyMenu />
        <div className={styles.pcr_content}>
          <Overview />
          <Benefits />
          <Workflow />
          <OpentronsMaterials />
          <Notes />
          <Resources />
        </div>
        {videoOpen && (
          <LightBox
            videoSrc={userVideo}
            onCloseClick={() => setVideoOpen(false)}
          />
        )}
      </div>
      <Related />
      <Community />
    </>
  )
}
