// @flow

import * as React from 'react'

import * as styles from './NAC.module.css'

import {Note} from '../../ui-components'

export const NOTE_DATA = [
  {
    name: 'Reagents',
    note: 'This application has been fully tested and scientifically verified in our lab with reagent partner Omega Bio-Tek. We recommend you use Omega Bio-Tek Total-Pure NGS for this application. If you would like to use another kit, the OT-2 supports various kits for nucleic acid extraction. Please confirm you have all required reagents required for your particular kit when you receive it.',
  },
  {
    name: 'Labware',
    note: (
      <p>
        The OT2 supports various labware for nucleic acid extraction. We support
        fully skirted .02 ml PCR plates, which are the best fit for the module
        itself. We can also support up to 2 ml{' '}
        <a
          className={styles.links}
          href="https://www.usascientific.com/2ml-deep96-well-plateone-bulk.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          deep well plates
        </a>
        , which might be helpful for larger volume kits. We suggest that you
        find a labware definition in our software that closely matches the
        labware in your lab. Alternatively, you can use the ‘labware.create’
        command to input the exact dimensions of your PCR plate. You can find
        more details about supported and custom labware on the OT-2 in our{' '}
        <a
          className={styles.links}
          href="https://docs.opentrons.com/v1/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Labware Library
        </a>
        .
      </p>
    ),
  },
  {
    name: 'Pipettes',
    note: (
      <p>
        Ensure that your OT-2 is fitted with suitable pipettes for the volumes
        you will need to work with. All of our pipettes can support these
        protocols, but pipette size can vary based on the size of the wash and
        the protocol you are using. For example, if you’re doing a 600 ml wash,
        you may need a p1000 pipette depending on your protocol. Please review
        our{' '}
        <a
          className={styles.links}
          href="https://s3.amazonaws.com/opentrons-landing-img/pipettes/OT-2-Pipette-White-Paper.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          OT-2 Pipette White Paper
        </a>{' '}
        for more information on our pipettes and pipetting volumes as low as 1
        µL (1).
      </p>
    ),
  },
]

export default function Notes() {
  return (
    <div>
      <section className={styles.notes}>
        <h4 className={styles.h4}>Notes</h4>
        {NOTE_DATA.map((note, i) => {
          return <Note key={i} {...note} />
        })}
      </section>
    </div>
  )
}
