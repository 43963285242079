// @flow

import * as React from 'react'
import {Link} from 'gatsby'

import * as styles from './NAC.module.css'

import {Menu} from '../../ui-components'

import {MENU_DATA} from './nucleic-acid-cleanup-data'

const protocolButtonProps = {
  children: 'BOOK CONSULTATION',
  to: '/request-protocol',
  Component: Link,
  gtm: {
    action: 'click',
    category: 'b-pcr',
    label: 'side-nav-call-button',
  },
  white: true,
}

const demoButtonProps = {
  children: 'SCHEDULE A DEMO',
  Component: 'a',
  white: true,
  linkOutProps: {
    url: 'https://insights.opentrons.com/schedule-demo',
    gtm: {
      action: 'click',
      category: 'b-pcr',
      label: 'side-demo-call-button',
    },
  },
}

export default function StickyMenu() {
  return (
    <div className={styles.desktop_menu}>
      <section className={styles.fixed_scroll}>
        <Menu
          {...MENU_DATA}
          protocolButtonProps={protocolButtonProps}
          demoButtonProps={demoButtonProps}
        />
      </section>
    </div>
  )
}
