// extracted by mini-css-extract-plugin
export var p = "NAC-module--p--MT7iH";
export var h3 = "NAC-module--h3--9BvJu";
export var h4 = "NAC-module--h4--6YARx";
export var h5 = "NAC-module--h5--CNSLv";
export var hero = "NAC-module--hero--MiiUx";
export var hero_container = "NAC-module--hero_container--UvR8G";
export var validation = "NAC-module--validation--EgJb1";
export var hero_h2 = "NAC-module--hero_h2--zsGg1";
export var container = "NAC-module--container--scxMV";
export var desktop_menu = "NAC-module--desktop_menu--iCucn";
export var fixed_scroll = "NAC-module--fixed_scroll--+j-00";
export var menu = "NAC-module--menu--78Nzo";
export var list = "NAC-module--list--F5PsB";
export var callback = "NAC-module--callback---XtHv";
export var button = "NAC-module--button--eJHsX";
export var pcr_content = "NAC-module--pcr_content--33ueN";
export var video = "NAC-module--video--lhvxm";
export var video_details = "NAC-module--video_details--2ZJIS";
export var video_container = "NAC-module--video_container--gPeyn";
export var figure = "NAC-module--figure--TFSfT";
export var play_icon = "NAC-module--play_icon--d-jKc";
export var overview_container = "NAC-module--overview_container--kfig2";
export var bold = "NAC-module--bold--XIDcG";
export var workflow = "NAC-module--workflow--0u0TY";
export var bullet_blue = "NAC-module--bullet_blue--ZdNsy";
export var benefits = "NAC-module--benefits--Iit27";
export var links = "NAC-module--links--xTmwV";
export var nac_workflow = "NAC-module--nac_workflow--L290t";
export var workflow_header = "NAC-module--workflow_header--C0Dvg";
export var material_container = "NAC-module--material_container--4cukX";
export var additional_materials = "NAC-module--additional_materials--7viPR";
export var opentrons_materials = "NAC-module--opentrons_materials--WyA1W";
export var notes = "NAC-module--notes--UfcKo";
export var resource_pdf = "NAC-module--resource_pdf--mQEoh";
export var resource_caption = "NAC-module--resource_caption--6+Odr";
export var resource_video = "NAC-module--resource_video--ws3AA";
export var resource_video_icon = "NAC-module--resource_video_icon--j8Ztt";
export var resources_content = "NAC-module--resources_content--Byt5F";
export var resource_data_notes = "NAC-module--resource_data_notes--jODVO";
export var related = "NAC-module--related--nda1L";
export var related_content_h4 = "NAC-module--related_content_h4--y4kFi";
export var related_application = "NAC-module--related_application--nMSy0";
export var related_bg_color = "NAC-module--related_bg_color--pMKlM";
export var related_container = "NAC-module--related_container--lTWUg";
export var related_button = "NAC-module--related_button--kkMHK";
export var related_content_card = "NAC-module--related_content_card--ydqxR";