// extracted by mini-css-extract-plugin
export var h3 = "Community-module--h3--KY26s";
export var h5 = "Community-module--h5--bbMnN";
export var community_container = "Community-module--community_container--YcAYJ";
export var community_section = "Community-module--community_section--LUGFQ";
export var user_story_card = "Community-module--user_story_card--i8O7D";
export var card_content = "Community-module--card_content--uTXaI";
export var gradient_bar = "Community-module--gradient_bar---QgRL";
export var blue = "Community-module--blue--VWUmc";
export var green = "Community-module--green--EmZ2s";
export var orange = "Community-module--orange--pPWrz";
export var user_img = "Community-module--user_img--zcFPy";
export var card_title = "Community-module--card_title--04ZHe";
export var title_blue = "Community-module--title_blue--a87uC";
export var title_green = "Community-module--title_green--rDiMo";
export var title_orange = "Community-module--title_orange--e9tSp";
export var user_quote = "Community-module--user_quote--9RMgn";
export var user_name = "Community-module--user_name--vLrB6";
export var user_lab = "Community-module--user_lab--5cRk6";
export var play_button = "Community-module--play_button--G8dBt";
export var button_wrapper = "Community-module--button_wrapper--ac+ci";
export var pub_button = "Community-module--pub_button--00ZWg";