// @flow
// User Story Card component, possible candidate for ui-components
import * as React from 'react'
import cx from 'classnames'
import * as styles from './Community.module.css'
import {IconButton, LightBox} from '../../../ui-components'

const PROPS_BY_COLOR = {
  blue: {
    titleStyle: styles.title_blue,
    barStyle: styles.blue,
    imgSrc: require('./assets/customer-story-blue.png').default,
  },
  green: {
    titleStyle: styles.title_green,
    barStyle: styles.green,
    imgSrc: require('./assets/customer-story-green.png').default,
  },
  orange: {
    titleStyle: styles.title_orange,
    barStyle: styles.orange,
    imgSrc: require('./assets/customer-story-orange.png').default,
  },
}

export type UserStoryProps = {
  color: 'blue' | 'green' | 'orange',
  title: string,
  userQuote: string,
  userName: string,
  userLab: string,
  userVideo: string,
  gtm: {|action: string, category: string, label: string|},
}

export default function UserStoryCard(props: UserStoryProps) {
  const [videoOpen, setVideoOpen] = React.useState(false)
  const {color, title, userQuote, userName, userLab, userVideo, gtm} = props
  const colorProps = PROPS_BY_COLOR[color]
  return (
    <div className={styles.user_story_card}>
      <div className={cx(styles.gradient_bar, colorProps.barStyle)} />
      <img src={colorProps.imgSrc} className={styles.user_img} />
      <div className={styles.card_content}>
        <h6 className={cx(styles.card_title, colorProps.titleStyle)}>
          {title}
        </h6>
        <p className={styles.user_quote}>{userQuote}</p>
        <p className={styles.user_name}>{userName}</p>
        <p className={styles.user_lab}>{userLab}</p>
        <IconButton
          className={styles.play_button}
          iconName="video-button-round"
          onClick={() => setVideoOpen(true)}
        >
          watch video
        </IconButton>
      </div>
      {videoOpen && (
        <LightBox
          videoSrc={userVideo}
          onCloseClick={() => setVideoOpen(false)}
          gtmCategory={gtm.category}
          gtmLabel={gtm.label}
          gtmAction={gtm.action}
        />
      )}
    </div>
  )
}
